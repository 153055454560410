<template>
  <v-stepper v-model="activeStep" class="custom-header" elevation="0">
    <v-snackbar v-model="alertDialog" color="error" :timeout="2000" :top="true" style="z-index: 1 !important">
      {{ alertText }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="alertDialog = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogSelectChannels" persistent width="500">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('broadcasts.selectChannel') }}
        </v-card-title>

        <v-divider></v-divider>
        <!-- <v-card-text>
          <v-card class="mx-auto" max-width="500">
            <v-list-group :value="true">
              <template v-slot:activator>
                <v-list-item-title>Groups</v-list-item-title>
              </template>
              <v-list>
                <v-list-item-group v-model="selectedGroup">
                  <v-list-item v-for="(item, index) in groupList" :key="index" @click="selectGroupData(item)">
                    <v-list-item-icon>
                      <v-avatar size="40">
                        <v-img :lazy-src="defaultProfPic" :src="item.profile_picture" height="40" width="40"> </v-img>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.group_name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>
          </v-card>
        </v-card-text> -->
        <v-card-text>
          <v-card class="mx-auto" max-width="500">
            <v-list-group :value="true">
              <template v-slot:activator>
                <v-list-item-title>Channels</v-list-item-title>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in listInstance" :key="i" @click="selectInstanceData(item)">
                    <!-- <v-list-item-icon>
                    <v-icon
                      large
                      :color="item.color"
                    >
                      {{ icons.mdiCircle }}
                    </v-icon>
                    <v-icon ></v-icon>
                  </v-list-item-icon> -->
                    <!-- <v-list-item-content> -->
                    <v-list-item-title
                      v-text="
                        `${
                          (item.label && item.label.startsWith('-')) ||
                          item.label.includes('Default') ||
                          item.label.includes('62')
                            ? item.label_server
                            : item.label
                        } `
                      "
                    ></v-list-item-title>
                    <v-list-item-icon>
                      <!-- <v-icon></v-icon> -->
                      <v-chip
                        class="p-0 pr-8 pl-1"
                        style="box-sizing: border-box"
                        small
                        :color="item.channelStatus === 1 ? '#2da583' : '#fbb4bb'"
                      >
                        {{ item.channelStatus === 1 ? 'Connected' : 'Disconnected' }}
                      </v-chip>
                    </v-list-item-icon>
                    <!-- </v-list-item-content> -->
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-list-group>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" class="mr-3" @click="dialogSelectChannels = false">
            {{ $t('cancel') }}
          </v-btn>
          <!-- <v-btn color="success" @click="openRoomSelected">
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Header -->
    <v-stepper-header>
      <!-- Header: Step 1 -->
      <v-stepper-step color="#F15858" :complete="activeStep > 1" step="1">
        <div class="d-flex align-center">
          <span class="text--primary text-4xl font-weight-bold me-3">01</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">{{ $t('broadcasts.stepOneTitle') }}</span>
            <span class="text--secondary text-xs">{{ $t('broadcasts.stepOneSubtitle') }}</span>
          </div>
        </div>
      </v-stepper-step>

      <v-divider
        style="border-width: 2px"
        :style="activeStep > 1 ? '' : { 'border-color': 'black', padding: '1px' }"
        :color="activeStep > 1 ? '#F15858' : 'white'"
      >
      </v-divider>

      <!-- Header: Step 2 -->
      <v-stepper-step color="#F15858" :complete="activeStep > 2" step="2">
        <div class="d-flex align-center">
          <span class="text--primary text-4xl font-weight-bold me-3">02</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">{{ $t('broadcasts.stepTwoTitle') }}</span>
            <span class="text--secondary text-xs">{{ $t('broadcasts.stepTwoSubtitle') }}</span>
          </div>
        </div>
      </v-stepper-step>

      <v-divider
        style="border-width: 2px"
        :style="activeStep > 2 ? '' : { 'border-color': 'black', padding: '1px' }"
        :color="activeStep > 2 ? '#F15858' : 'white'"
      >
      </v-divider>

      <!-- Header: Step 3 -->
      <v-stepper-step color="#F15858" step="3">
        <div class="d-flex align-center">
          <span class="text--primary text-4xl font-weight-bold me-3">03</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">{{ $t('broadcasts.stepThreeTitle') }}</span>
            <span class="text--secondary text-xs">{{ $t('broadcasts.stepThreeSubtitle') }}</span>
          </div>
        </div>
      </v-stepper-step>
    </v-stepper-header>

    <!-- Stepper Items -->
    <v-stepper-items>
      <!-- Stepper Content: Step 1 -->
      <v-stepper-content step="1">
        <v-card elevation="0">
          <v-card-text>
            <v-row>
              <v-autocomplete v-model="channel" :items="channelList" outlined dense :label="$t('broadcasts.channel')">
                <template #selection="data">
                  <span class="mr-1"> {{ data.item.text }} </span>
                  <v-chip small :color="data.item.channelStatus === 1 ? '#2da583' : '#fbb4bb'">
                    {{ data.item.channelStatus === 1 ? 'Connected' : 'Disconnected' }}
                  </v-chip>
                </template>
                <template #item="data">
                  <span class="mr-1"> {{ data.item.text }} </span>
                  <v-chip small :color="data.item.channelStatus === 1 ? '#2da583' : '#fbb4bb'">
                    {{ data.item.channelStatus === 1 ? 'Connected' : 'Disconnected' }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-row>
            <div v-if="(!fromContacts && !fromList) || isDraft">
              <v-row>
                <v-radio-group v-model="sourceContact" class="mt-0 mb-3" hide-details @change="showContactInTable()">
                  <template #label>
                    <div>
                      {{ $t('broadcasts.pleaseSelect') }} <strong>{{ $t('broadcasts.contactSource') }}</strong> :
                    </div>
                  </template>
                  <v-radio value="Excel">
                    <template #label>
                      <div>{{ $t('broadcasts.chooseFrom') }} <strong class="error--text">Excel</strong></div>
                    </template>
                  </v-radio>
                  <v-radio value="Whatsapp">
                    <template #label>
                      <div>
                        {{ $t('broadcasts.chooseFrom') }} <strong class="success--text">Whatsapp</strong>
                        {{ $t('Contact') }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="Tags">
                    <template #label>
                      <div>
                        {{ $t('broadcasts.chooseFrom') }}
                        <strong class="primary--text"> Labels </strong>
                      </div>
                    </template>
                  </v-radio>
                  <!-- <v-radio value="Custom">
                    <template #label>
                      <div>
                        {{ $t('broadcasts.customFrom') }}
                        <strong class="primary--text">
                          {{ $t('broadcasts.otherList') }}
                        </strong>
                      </div>
                    </template>
                  </v-radio> -->
                </v-radio-group>
              </v-row>
              <v-row>
                <div v-if="fromExcel">
                  <v-col cols="12">
                    <span class="text-subtitle-2 mb-2 font-weight-bold">{{ $t('broadcasts.uploadFormat') }}</span>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="importFileExcel"
                      outlined
                      dense
                      type="file"
                      :label="$t('contacts.chooseExcel')"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="getExcel"
                    />
                  </v-col>
                  <!-- <v-divider /> -->
                  <v-col v-if="!$vuetify.breakpoint.xs" cols="12" class="d-flex my-auto">
                    <span class="text-subtitle-2 font-weight-regular">{{ $t('broadcasts.noTemplate') }}</span>
                    <v-btn class="ms-3" color="primary" href="Template Broadcast Excel.xlsx" download>
                      <v-icon class="me-2" dark>
                        {{ icons.mdiDownload }}
                      </v-icon>
                      Download {{ $t('broadcasts.hero.here') }}
                    </v-btn>
                  </v-col>

                  <div v-else>
                    <v-col cols="12">
                      <span class="text-subtitle-2 font-weight-regular">{{ $t('broadcasts.noTemplate') }}</span>
                    </v-col>

                    <v-col cols="12">
                      <v-btn class="ms-3" color="primary" href="Template Broadcast Excel.xlsx" download>
                        <v-icon class="me-2" dark>
                          {{ icons.mdiDownload }}
                        </v-icon>
                        Download {{ $t('broadcasts.hero.here') }}
                      </v-btn>
                    </v-col>
                  </div>
                </div>
                <div v-if="sourceContact === 'Tags'" class="mt-n1">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-combobox
                        v-model="selectedTags"
                        attach
                        chips
                        label="Labels"
                        multiple
                        outlined
                        dense
                        :items="listTags"
                        :item-text="item => (item.v ? item.name + ' - ' + item.v : item.name)"
                        :disabled="tableLoading"
                        class="tag-combobox"
                        @change="showContactInTableFromTags()"
                      >
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-combobox
                        v-model="selectedExcludedTags"
                        attach
                        chips
                        label="Excluded Labels"
                        multiple
                        outlined
                        dense
                        :items="listTags"
                        :item-text="item => (item.v ? item.name + ' - ' + item.v : item.name)"
                        :disabled="tableLoading"
                        class="tag-combobox"
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>
                </div>

                <v-expand-transition>
                  <v-combobox
                    v-show="showListContact"
                    v-model="selectedListContacts"
                    :items="listContactsItem"
                    label="List Contact"
                    multiple
                    chips
                    attach
                    return-object
                    class="mb-0"
                    @change="showContactInTable()"
                  ></v-combobox>
                </v-expand-transition>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" md="12">
                <v-alert
                  v-if="duplicateContactCount > 0"
                  color="secondary"
                  border="left"
                  colored-border
                  dense
                  text
                  class="caption mb-0 mt-0"
                >
                  {{ $t('broadcasts.thereAre') }} <strong>{{ duplicateContactCount }}</strong>
                  {{ $t('broadcasts.duplicatePhone') }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-alert
                  v-if="unsubscribeContactCount > 0"
                  color="secondary"
                  border="left"
                  colored-border
                  dense
                  text
                  class="caption mb-0 mt-0"
                >
                  {{ $t('broadcasts.thereAre') }} <strong>{{ unsubscribeContactCount }}</strong>
                  {{ $t('broadcasts.unsubscribePhone') }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="mt-1 mb-2" style="border-width: 0.1px" />
            </v-row>

            <v-row>
              <v-chip color="#EEF1F4" class="ml-3">
                <span style="color: #f15858">{{ $t('broadcasts.selected') }} contacts : {{ dataContacts.length }}</span>
              </v-chip>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="searchDetail"
                  :append-icon="icons.mdiMagnify"
                  :label="$t('broadcasts.search')"
                  single-line
                  hide-details
                  dense
                  outlined
                  class="mr-1 mb-1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-checkbox v-model="allBroadcastData" class="ml-n1 mt-4" :label="$t('selectall')"></v-checkbox>
          </v-card-text>
        </v-card>
        <v-card elevation="0">
          <!-- <pre>{{ dataContacts }}</pre> -->
          <v-data-table
            v-model="dataContacts"
            :headers="showListContact ? headerContactList : headerContact"
            :items="detailListContact"
            :loading="tableLoading"
            item-key="id"
            :show-select="(!fromContacts && !fromList) || isDraft"
            :options.sync="options"
            :server-items-length="totalData"
            :footer-props="{
              'items-per-page-text': $t('rowsPerPageLabel'),
              'items-per-page-options': [5, 10, 30, 50, 100],
            }"
            class="table-broadcast"
            checkbox-color="#111b21"
          >
            <template slot="no-data">
              {{ $t('noDataLabel') }}
            </template>
            <template #[`item.phone_number`]="{ item }">
              {{ item.phone_number.length > 16 ? 'Group' : item.phone_number }}
            </template>
            <!-- <template #[`item.custom`]="{ item }">
              <span v-html="item.custom"> </span>
            </template> -->
            <template #[`item.name`]="{ item }">
              <div class="display-lg align-center">
                <v-avatar v-if="item ? (item.profile_picture ? true : false) : false" size="40">
                  <v-img :src="item.profile_picture || ''" height="40" width="40"> </v-img>
                </v-avatar>
                <div class="display-lg flex-column ms-3 display-mt-lg">
                  <span class="d-block font-weight-semibold text-truncate text--primary sm-text">{{ item.name }}</span>
                  <!-- <span class="text-xs">{{ item.phone_number }}</span> -->
                </div>
              </div>
            </template>
            <template #[`item.created_at`]="{ item }">
              {{ $moment(item.created_at).format('DD-MM-YYYY') }}
            </template>
            <template #[`item.updated_at`]="{ item }">
              {{ $moment(item.updated_at).format('DD-MM-YYYY') }}
            </template>
            <!-- <template #[`item.Media`]="{ item }">
              <v-img
                :src="item.Media"
                height="50"
                width="50"
              >
              </v-img>
            </template> -->
          </v-data-table>
        </v-card>
        <div class="d-flex justify-end">
          <v-btn v-if="fromExcel" color="#F15858" class="text-white" @click="stepThree()">
            {{ $t('next') }}
          </v-btn>
          <v-btn v-else color="#F15858" class="text-white" @click="stepTwo()">
            {{ $t('next') }}
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- Stepper Content: Step 2 -->
      <v-stepper-content step="2">
        <v-card class="pa-3" elevation="0">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                id="firstname"
                v-model="broadcastName"
                outlined
                dense
                :label="$t('broadcasts.broadcastName')"
                hide-details
                class="mb-1"
              ></v-text-field>
              <v-switch
                v-model="changeMessage"
                class="col-md-3 col-sm-5 col-lg-3 col-12"
                :label="$t('broadcasts.customMessage')"
                hide-details
              ></v-switch>
              <v-autocomplete
                v-if="!changeMessage"
                v-model="chosenTemplate"
                :items="allTemplates"
                :label="$t('broadcasts.messageTemplate')"
                outlined
                return-object
                item-text="template_name"
                dense
                @change="e => (chosenTemplate = e)"
              ></v-autocomplete>
              <!-- custom message template -->
              <v-expand-transition>
                <message-template
                  v-if="changeMessage"
                  ref="message-broadcast"
                  :source-contact="sourceContact"
                  :selected-list-contacts="selectedListContacts"
                  :data-extra-field-props="dataExtraFieldProps"
                  :active-step="activeStep"
                  @update-message="nextStep"
                />
              </v-expand-transition>
              <v-row>
                <v-expand-transition>
                  <v-col v-if="extraFieldAlert" cols="12" md="12">
                    <v-alert color="secondary" border="left" colored-border dense text class="caption">
                      <strong>Not All Field</strong> in your message templates, available in all
                      <strong>Contact List Selected</strong>
                    </v-alert>
                  </v-col>
                </v-expand-transition>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <div class="d-flex justify-end">
          <v-btn class="me-3 font-weight-700" style="background-color: #eef1f4; color: #f15858" @click="activeStep = 1">
            <span color="#F15858">{{ $t('previous') }}</span>
          </v-btn>
          <v-btn color="#F15858" class="text-white" @click="stepThree()">
            {{ $t('next') }}
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- Stepper Content: Step 3 -->
      <v-stepper-content step="3">
        <v-card elevation="0">
          <v-card-text>
            <v-row>
              <v-col v-if="!fromExcel" cols="12" md="5">
                <preview-message
                  :message-preview="messagePreview"
                  :message-preview-img="messagePreviewImg"
                ></preview-message>
              </v-col>
              <v-col cols="12" md="7">
                <span class="text-h5">
                  <strong>Broadcast Detail</strong>
                </span>
                <table class="billing-address-table">
                  <tr>
                    <td class="billing-title">
                      <p class="text-no-wrap text--primary font-weight-medium">Broadcast Name</p>
                    </td>
                    <td>
                      <p><strong> : </strong>{{ broadcastName }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="billing-title">
                      <p class="text-no-wrap text--primary font-weight-medium">Channel</p>
                    </td>
                    <td>
                      <p><strong> : </strong>{{ channelText }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="billing-title">
                      <p class="text-no-wrap text--primary font-weight-medium">
                        {{ $t('broadcasts.messageTemplate') }}
                      </p>
                    </td>
                    <td>
                      <p v-if="!fromExcel"><strong> : </strong>{{ messageTemplateText }}</p>
                      <p v-else><strong> : </strong>Custom</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="billing-title">
                      <p class="text-no-wrap text--primary font-weight-medium">
                        {{ $t('broadcasts.numberContacts') }}
                      </p>
                    </td>
                    <td>
                      <p><strong> : </strong>{{ dataContacts.length }}</p>
                    </td>
                  </tr>
                </table>
                <!-- <v-switch
                  v-model="trackLink"
                  dense
                  class="mb-1 mt-1"
                  :disabled="isLinkAvailable ? false : true"
                  :label="`Track Link : ${
                    isLinkAvailable
                      ? trackLink
                        ? $t('broadcasts.linkOn')
                        : $t('broadcasts.linkOff')
                      : $t('broadcasts.noLink')
                  }`"
                ></v-switch> -->
                <!-- <v-switch
                  v-model="unsubscribe"
                  dense
                  class="mb-1 mt-1"
                  :disabled="sourceContact === 'Whatsapp' || fromContacts"
                  :label="`Allow Unsubscribe : ${unsubscribe
                    ? $t('broadcasts.linkOn')
                    : $t('broadcasts.linkOff')}`"
                  @change="changeUnsubscribe()"
                ></v-switch> -->
                <!-- <v-switch
                  v-model="showBroadcastMessage"
                  dense
                  class="mb-1 mt-1"
                  :label="$t('broadcasts.showBroadcastMessage')"
                ></v-switch> -->
                <!-- Auto delete broadcast (Only from your device) -->

                <v-combobox
                  v-model="broadcastMode"
                  :items="broadcastModeOptions"
                  label="Broadcast Mode"
                  class="py-0 mb-0 mt-6"
                  return-object
                >
                  <template v-slot:append-outer>
                    <v-tooltip :open-on-click="true" :open-on-hover="false" left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark icon v-bind="attrs" v-on="on" @click="on.click">
                          <v-icon>
                            {{ icons.mdiInformation }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Aggresive : {{ $t('broadcasts.broadcastModeAggresive') }}</span>
                      <br />
                      <span>Safe : {{ $t('broadcasts.broadcastModeSafe') }}</span>
                    </v-tooltip>
                  </template>
                </v-combobox>

                <!-- <v-radio-group
                  v-model="delayType"
                  class="py-0 my-0 mb-5"
                  row
                  hide-details
                >
                  <template #label>
                    <div>
                      <strong> {{ $t('broadcasts.sendingSpeed') }} : </strong>
                    </div>
                  </template>
                  <v-radio
                    label="Fast"
                    value="fast"
                    @click="checkDelayType()"
                  ></v-radio>
                  <v-radio
                    label="Auto"
                    value="auto"
                    @click="checkDelayType()"
                  ></v-radio>
                </v-radio-group> -->

                <div v-if="!fromExcel">
                  <v-combobox
                    v-model="executionType"
                    :items="execTypeOptions"
                    label="Time To Send"
                    hide-details
                    @change="checkExecType()"
                  ></v-combobox>
                  <v-slide-y-transition>
                    <v-row style="margin-top: 10px">
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menuDate"
                          v-model="menuDatePicker"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker"
                          transition="scale-transition"
                          bottom
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-slide-y-transition>
                              <v-text-field
                                v-show="showMenuDateTime"
                                v-model="dateText"
                                transition="scroll-y-transition"
                                small
                                dense
                                label="Date"
                                :prepend-icon="icons.mdiCalendarMonth"
                                v-bind="attrs"
                                v-on="on"
                              >
                              </v-text-field>
                            </v-slide-y-transition>
                          </template>
                          <v-date-picker
                            v-model="datePicker"
                            no-title
                            scrollable
                            :min="minDatePicker"
                            :max="maxDatePicker"
                            :locale="locale"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="#ffeeee" @click="menuDatePicker = false">
                              <span class="error--text">{{ $t('cancel') }}</span>
                            </v-btn>
                            <v-btn
                              text
                              color="success"
                              @click="
                                $refs.menuDate.save(datePicker)
                                checkTimePicker(datePicker)
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menuTime"
                          v-model="menuTimePicker"
                          :close-on-content-click="false"
                          :return-value.sync="timePicker"
                          transition="scale-transition"
                          bottom
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-slide-y-transition>
                              <v-text-field
                                v-show="showMenuDateTime"
                                v-model="timeText"
                                :disabled="executionType.value != 'datetime'"
                                transition="scroll-y-transition"
                                small
                                dense
                                label="Time"
                                :prepend-icon="icons.mdiClockTimeEight"
                                v-bind="attrs"
                                v-on="on"
                              >
                              </v-text-field>
                            </v-slide-y-transition>
                          </template>
                          <v-time-picker v-model="timePicker" format="24hr" :min="minTimePicker" :locale="locale">
                            <v-spacer></v-spacer>
                            <v-btn text color="#ffeeee" @click="menuTimePicker = false">
                              <span class="error--text">{{ $t('cancel') }}</span>
                            </v-btn>
                            <v-btn text color="success" @click="$refs.menuTime.save(timePicker)"> OK </v-btn>
                          </v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- <div class="d-flex justify-end"> -->
        <v-row class="d-flex justify-end text-end">
          <v-col cols="12">
            <v-btn
              v-if="!fromExcel"
              :block="$vuetify.breakpoint.mdAndDown ? true : false"
              class="me-2 font-weight-700"
              style="background-color: #eef1f4; color: #f15858"
              :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''"
              @click="activeStep = 2"
            >
              <span>{{ $t('previous') }}</span>
            </v-btn>

            <v-btn
              v-if="fromExcel"
              :block="$vuetify.breakpoint.mdAndDown ? true : false"
              class="me-2 font-weight-700"
              style="background-color: #eef1f4; color: #f15858"
              :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''"
              @click="activeStep = 1"
            >
              <span>{{ $t('previous') }}</span>
            </v-btn>

            <v-btn
              :block="$vuetify.breakpoint.mdAndDown ? true : false"
              color="#EEF1F4"
              class="me-2"
              :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''"
              @click="complete(true)"
            >
              <span style="color: #707683">{{ $t('broadcasts.saveDraft') }}</span>
            </v-btn>

            <v-btn
              :block="$vuetify.breakpoint.mdAndDown ? true : false"
              color="#F15858"
              class="me-2 text-white"
              :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''"
              @click="complete()"
            >
              {{ $t('broadcasts.send') }}
            </v-btn>
          </v-col>
          <!-- <v-col md="4" lg="4" sm="12"> </v-col>
          <v-col md="4" lg="4" sm="12"> </v-col>
          <v-col md="4" lg="4" sm="12"> </v-col> -->
          <!-- <v-col md="3" offset-md="2" lg="3" offset-lg="2" sm="12">
              <v-btn color="secondary" class="me-2" @click="preview()">
                {{ $t('broadcasts.sendPreview') }}
              </v-btn>
            </v-col> -->
        </v-row>
        <!-- </div> -->
      </v-stepper-content>
    </v-stepper-items>
    <!-- custom delay -->
    <v-dialog v-model="customDelayDialog" hide-overlay width="400">
      <v-card>
        <v-toolbar color="#F1585833" class="mb-3" elevation="0">
          <div class="text-h6" style="color: #f15858">
            {{ $t('broadcasts.customDelay') }}
          </div>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <span class="text-h7"
                ><strong>{{ $t('broadcasts.delayInterval') }}</strong> ({{ $t('broadcasts.seconds') }}) :</span
              >
              <v-text-field
                v-model="delayMin"
                :label="$t('broadcasts.delayFrom')"
                outlined
                dense
                hide-details
                class="mt-1 mb-2"
              ></v-text-field>

              <v-text-field
                v-model="delayMax"
                :label="$t('broadcasts.delayTo')"
                outlined
                dense
                hide-details
                class="mt-1 mb-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider style="margin: 10px"></v-divider>
          <v-row>
            <v-col cols="12" md="12">
              <span class="text-h7"
                ><strong>{{ $t('broadcasts.restMode') }}</strong> :</span
              >
              <v-text-field
                v-model="stopAfter"
                :label="$t('broadcasts.stopAfter')"
                outlined
                dense
                hide-details
                class="mt-1 mb-2"
              ></v-text-field>

              <v-text-field
                v-model="additionalDelay"
                :label="$t('broadcasts.additionalDelay')"
                outlined
                dense
                hide-details
                class="mt-1 mb-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#ffeeee"
            @click="
              customDelayDialog = false
              delayType = 'fast'
            "
          >
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="#00D6D1" class="text-white" @click="customDelayDialog = false">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog loading -->
    <v-dialog v-model="loadingDialog" hide-overlay width="320">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ loadingText }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingFetchContacts" persistent width="320">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          <span>Please Wait....</span>
          <v-progress-linear indeterminate color="white"></v-progress-linear>

          <!-- <div class="d-flex align-end justify-end">
            <v-btn
              color="success"
              class="mt-4"
              small
              @click="skipLoad"
            >
              <span>Skip</span>
            </v-btn>
          </div> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-stepper>
</template>

<script>
/* eslint-disable */
import messageTemplate from '@/components/dialog/broadcast/message_template.vue'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiCalendarMonth, mdiCircle, mdiClockTimeEight, mdiDownload, mdiInformation, mdiMagnify } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import { adjectives, colors, uniqueNamesGenerator } from 'unique-names-generator'
import XLSX from 'xlsx'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import PreviewMessage from './PreviewMessage.vue'

export default {
  components: { PreviewMessage, messageTemplate },
  setup() {
    const searchDetail = ref('')
    const { isDark } = useAppConfig()

    return {
      icons: { mdiCalendarMonth, mdiCircle, mdiDownload, mdiClockTimeEight, mdiMagnify, mdiInformation },
      searchDetail,
      isDark,
    }
  },
  mixins: [pouchDbMixin, firestoreDbMixin],
  props: {
    fromContacts: { type: Boolean, default: false },
    fromList: { type: Boolean, default: false },
    contactsFromWa: { type: Array, default: () => [] },
    listSelected: { type: Array, default: () => [] },
    isDraft: { type: Boolean, default: false },
    broadcastData: { type: Object, default: () => {} },
    dataContactsFromApi: { type: Object, default: () => {} },
    fromChat: { type: Boolean, default: false },
    // defaultCustomMessage: { type: String, default: null }
  },
  data() {
    return {
      totalData: 0,
      options: {},
      selectedTags: [],
      listTags: [],
      groupList: [],
      selectedGroup: '',
      windowHeight: window.innerHeight,
      deleteForMe: true,
      showBroadcastMessage: true,

      // select channel
      dialogSelectChannels: false,

      // custom template
      listTemplateCustom: null,

      // edit message
      broadcastId: '',

      // add broadcast
      changeMessage: false,
      activeStep: 1,
      loadingText: '',
      showMenuDateTime: false,
      alertDialog: false,
      alertText: '',
      datePicker: '',
      menuDatePicker: false,
      timePicker: '',
      menuTimePicker: false,
      minDatePicker: new Date().toISOString(),
      maxDatePicker: null,
      listInstance: [],
      listTemplates: [],
      executionType: { text: 'Immediately', value: 'immediately' },
      channel: 0,
      channelList: [],
      messageTemplate: 0,
      messageTemplateList: [],
      broadcastName: uniqueNamesGenerator({
        dictionaries: [adjectives, colors],
        style: 'capital',
        separator: ' ',
      }),
      detailListContact: [],
      headerContact: [],
      headerContactList: [],

      dataContacts: [],
      executionRecurrence: {
        repeatOption: {
          type: 'every_day',
          everyWeekPicker: ['1', '2', '3', '4', '5', '6', '7'],
        },
        repeatAt: '10:00',
      },
      delayTime: 10,
      loadingDialog: false,
      delayType: 'fast',
      delayMin: 0,
      delayMax: 0,
      stopAfter: 0,
      delay: 10000,
      additionalDelay: 0,
      customDelayDialog: false,
      messagePreview: { message: '', attachments: [] },
      messagePreviewImg: [],
      isLinkAvailable: false,
      trackLink: false,
      unsubscribe: false,
      execTypeOptions: [],
      contactsListId: [],
      contactsList: {},
      sourceContact: 'Whatsapp',
      selectedListContacts: [],
      listContactsItem: [],
      showListContact: false,
      duplicateContactCount: 0,
      unsubscribeContactCount: 0,
      contactIdWA: '',
      extraFieldAlert: false,
      localExtraFieldContainer: [],
      broadcastContactsTemp: {},
      broadcastMode: { text: 'Safe', value: 'safe' },
      broadcastModeOptions: [],
      dataExtraFieldProps: {},
      importFileExcel: null,
      fromExcel: false,
      tableLoading: false,
      allTemplates: [],
      chosenTemplate: {},
      messageFromMessageTemplate: {},
      allBroadcastData: false,
      loadingFetchContacts: false,
      selectedExcludedTags: [],
    }
  },
  computed: {
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    // listInstance() {
    //   const instances = this.$store.getters['auth/getListInstance']
    //   return instances.value
    // },
    minTimePicker() {
      let dateNow = new Date()
      let dateChoose = this.datePicker !== '' ? new Date(this.datePicker) : dateNow
      if (dateNow.getTime() === dateChoose.getTime()) return `${new Date().getHours()}:${new Date().getMinutes()}`
      else if (
        dateNow.getDate() === dateChoose.getDate() &&
        dateNow.getMonth() === dateChoose.getMonth() &&
        dateNow.getFullYear() === dateChoose.getFullYear()
      ) {
        return `${new Date().getHours()}:${new Date().getMinutes() + 1}`
      } else return null
    },
    screenSize() {
      return this.isMini()
    },
    dateText() {
      return this.datePicker
    },
    timeText() {
      return this.timePicker
    },
    messageTemplateText() {
      if (this.changeMessage) return 'Custom'
      if (this.chosenTemplate) {
        return this.chosenTemplate.template_name
      }

      return ''
    },
    channelText() {
      if (this.channelList.length !== 0) {
        if (this.channel !== '' || this.channel !== null) {
          if (this.channelList[this.channel]) {
            return this.channelList[this.channel].text
          }
        }
      }

      return ''
    },
    locale() {
      return this.$i18n.locale
    },
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    sourceContact() {
      this.searchDetail = ''
      if (this.sourceContact === 'Excel') {
        this.importFileExcel = null
        this.totalData = 0
      }
    },
    fromChat: {
      immediate: true,
      handler(val, newVal) {
        if (val) {
          this.changeMessage = true
        }
      },
    },
    searchDetail: _debounce(function (ev) {
      switch (this.sourceContact) {
        case 'Whatsapp':
          this.showContactInTable(ev)
          break
        case 'Excel':
          this.getExcel(ev)
          break
        case 'Tags':
          this.showContactInTableFromTags(ev)
          break
        default:
          break
      }
    }, 1000),
    allBroadcastData(val, newVal) {
      if (val) {
        this.getAllBroadcastData()
      } else {
        this.dataContacts = []
      }
    },
    options: {
      handler() {
        switch (this.sourceContact) {
          case 'Whatsapp':
            this.showContactInTable()
            break
          case 'Excel':
            this.getExcel()
            break
          case 'Tags':
            this.showContactInTableFromTags()
            break

          default:
            break
        }
      },
      deep: true,
    },
    activeStep(varnew) {
      this.nextStep(varnew)
    },
    channel() {
      this.showContactInTable()
    },
    selectedExcludedTags: {
      handler() {
        if (this.selectedTags.length > 0) {
          this.showContactInTableFromTags()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.loadingText = this.$t('broadcasts.checkChannels')
    this.loadingDialog = true

    await this.getGroups()
    this.getAllTemplates()

    // this.maxDatePicker = new Date(Number(this.user.expire_date)).toISOString()

    this.headerContact = [
      { text: this.$t('phoneNumber'), value: 'phone_number' },
      { text: this.$t('name'), value: 'name' },

      // { text: 'Has Wa', value: 'has_wa' },
      { text: 'Contact', value: 'source' },
    ]
    this.headerContactList = [
      { text: this.$t('phoneNumber'), value: 'no', sortable: true },
      { text: this.$t('name'), value: 'name', sortable: true },

      { text: 'Has Wa', value: 'has_wa' },
      { text: 'Custom Attributes', value: 'custom', sortable: false },
      { text: 'Created At', value: 'created_at', sortable: true },
      { text: 'Updated At', value: 'updated_at', sortable: false },
    ]
    this.executionType = { text: this.$t('broadcasts.immediately'), value: 'immediately' }
    this.messagePreview = { message: this.$t('broadcasts.chooseTemplateFirst'), attachments: [] }

    this.execTypeOptions = [
      { text: this.$t('broadcasts.immediately'), value: 'immediately' },
      { text: this.$t('broadcasts.datetime'), value: 'datetime' },
      { text: this.$t('broadcasts.today08am'), value: '08:00' },
      { text: this.$t('broadcasts.noon01pm'), value: '13:00' },
      { text: this.$t('broadcasts.tonight7pm'), value: '19:00' },
    ]

    this.broadcastModeOptions = [
      { text: 'Aggressive', value: 'aggressive' },
      { text: 'Safe', value: 'safe' },
    ]

    this.listInstance = (
      await clientPocketBase.collection('crm_instances').getFullList(400, {
        filter: `broadcast_group = false`,
        expand: 'instance_label_name',
        $autoCancel: false,
      })
    )
      .map(inst => {
        if (Object.keys(inst.expand).length) {
          const editedName = inst.expand.instance_label_name.edited_name
          if (editedName) {
            inst.label = editedName
            inst.label_server = editedName
          }
        }
        return inst
      })
      .filter(instance => {
        if (!instance.inbox_id) {
          if (this.user.limited_channel) {
            return (
              this.user.enabled_channel &&
              this.user.enabled_channel.find(channel => channel === instance._id || channel === instance.phone_number)
            )
          }
          return true
        }
        return false
      })

    if (this.listInstance.length === 0) {
      this.alertDialog = true
      this.alertText = 'No Channels'
      this.$store.dispatch('link/sendLinkToBroadcast', '')
      // this.$emit('close-dialog')
    }
    for (let idx = 0; idx < this.listInstance.length; idx++) {
      const el = this.listInstance[idx]
      let channelStatus = await this.$store.dispatch('broadcast/getChannelStatus', `${el.sub_id}-${el._id}`)
      el.channelStatus = channelStatus
      if (!el.fs_id || !el.access_token) {
        let retData = {
          text:
            el.label === '-' || el.label.includes('Default 62') || el.label.includes('62') ? el.label_server : el.label,
          channelStatus: channelStatus,
          value: idx,
          ...el,
        }
        this.channelList.push(retData)
      }
    }

    this.loadingDialog = false
    this.dialogSelectChannels = true
  },
  methods: {
    nextStep(message) {
      if (this.activeStep === 3) {
        const regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

        if (this.changeMessage === true) {
          let attachment = []
          let attachmentReply = []

          // const customMessage = {
          //   id: uuidv4(),
          //   last_update: moment().format('DD-MM-YYYYTHH:mm:ss'),
          //   ad_hoc: this.changeMessage,
          //   message: message.message,
          //   //turn off on reply
          //   on_reply_message: { message: '', attachments: [] },
          //   on_reply: false,
          // }

          const customMessage = message

          // add onreply image
          if (attachmentReply.length > 0) {
            customMessage.on_reply_message.attachments = attachmentReply
            customMessage.on_reply = true
          }

          // check if there is on reply message
          // if (customMessage.on_reply_message.message.length !== 0) {
          //   customMessage.on_reply = true
          // }
          this.chosenTemplate = customMessage
          this.listTemplateCustom = customMessage
          // this.isLinkAvailable = this.listTemplateCustom.message.match(regexp)
          if (!this.isLinkAvailable) this.trackLink = false
        } else {
          //console.log(this.listTemplates, this.messageTemplate, 'ini dia bawh')
          if (this.listTemplates && this.listTemplates[this.messageTemplate]) {
            this.isLinkAvailable = this.listTemplates[this.messageTemplate].message.match(regexp)
          }
          if (!this.isLinkAvailable) this.trackLink = false
        }
        this.setMessagePreview()
      }
      if (this.activeStep === 2 && this.isDraft) {
        if (this.$refs['message-broadcast']) {
          const refsMess = this.$refs['message-broadcast']
          // eslint-disable-next-line no-underscore-dangle
          refsMess.setMessageTemplate(this.broadcastData.message_template, this.broadcastData._id)
        }
      }
    },
    async getGroups() {
      const groups = await clientPocketBase.collection('crm_contact_group').getFullList(400, {})
      this.groupList = groups
    },
    async getAllBroadcastData() {
      try {
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/fetch-all-broadcast-contacts`
        const payload = {
          sub_id: this.user.sub_id,
          instance: this.listInstance[this.channel],
          labels: [],
          excludedTags: this.selectedExcludedTags.map(excludedTag => excludedTag.id),
        }
        this.loadingFetchContacts = true
        if (this.selectedTags.length) {
          payload.labels = this.selectedTags
        }
        const { data: allContacts } = await axios.post(endpoint, payload)
        this.dataContacts = allContacts?.data || []
        this.loadingFetchContacts = false
      } catch (err) {
        console.log(err, 'halo<')
      }
    },
    async selectGroupData(data) {
      this.dialogSelectChannels = false
      const selectedBcGroup = await clientPocketBase.collection('crm_contact_group').getOne(data.id, {
        expand: 'instances',
        $autoCancel: false,
      })

      for (let i = 0; i < selectedBcGroup.expand.instances.length; i++) {
        const element = selectedBcGroup.expand.instances[i]

        const allContact = await clientPocketBase.collection('crm_contact').getFullList(400, {
          filter: `broadcast_group = true && instance = "${element.id}"`,
          $autoCancel: false,
        })
        // console.log('sayang contact', allContact)

        this.detailListContact.push(...allContact)
      }
      this.showListContact = false
      this.tableLoading = false
    },
    selectInstanceData(data) {
      this.dialogSelectChannels = false
      this.channel = this.listInstance.findIndex(instance => instance._id === data._id)
      this.showContactInTable()
    },
    async getAllTemplates() {
      let foundTemplates = await clientPocketBase.collection('crm_message_templates').getFullList(400)
      this.allTemplates = foundTemplates
    },
    getExcel(searchValue) {
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let required = []
      let counter = 0
      this.detailListContact = []
      this.dataContacts = []
      const reader = new FileReader()
      reader.readAsBinaryString(this.importFileExcel)
      // eslint-disable-next-line no-shadow
      reader.onload = async e => {
        const binaryData = e.target.result
        const workbook = XLSX.read(binaryData, { type: 'binary' })
        let data = []

        // //console.log(workbook, `INI WORKBOOK`)
        workbook.SheetNames.forEach(el => {
          // //console.log(workbook.Sheets, '=============', el, `INI EL DAN WORKSHEET`)
          data = XLSX.utils.sheet_to_json(workbook.Sheets[el])
        })
        // //console.log(data, `INI DATA`)
        // const tempField = []
        data.forEach(el => {
          el.id = this.$nanoid()
          el['phone_number'] = el['Phone Number'].toString()

          if (!el['Date']) {
            required.push(`Date on ${el['Phone Number']} is empty`)
          }

          if (!el['Time']) {
            required.push(`Time on ${el['Phone Number']} is empty`)
          }

          if (!el['Phone Number']) {
            counter++
            required.push(`There is ${counter} empty phone number`)
          }

          // //console.log(el['Date'], el['Time'], `INI EL`)
          // for (const key in el) {
          //   el.tempField.push(key)
          // }
        })

        if (required.length !== 0) {
          // //console.log(required, `INI REQ`)
          if (required.length > 1) required.splice(required.length - 1, 0, '{--}')
          this.alertText = `${required.join(', ').replace(', {--},', ' and')}`
          this.alertDialog = true
          this.importFromExcel = null
        } else {
          function paginate(array, page_size, page_number) {
            // Determine the starting and ending indices for the current page
            const start_index = (page_number - 1) * page_size
            const end_index = start_index + page_size

            // Return the current page's items as a new array
            return array.slice(start_index, end_index)
          }
          if (searchValue && typeof searchValue === 'string') {
            // console.log(data, searchValue, 'cek data nyund')
            const filter = data.filter(datum => {
              // console.log(datum.Name, searchValue)
              if (
                datum.Name.toLowerCase().includes(searchValue.toLowerCase()) ||
                datum.phone_number.includes(searchValue) ||
                datum.Message.toLowerCase().includes(searchValue.toLowerCase())
              ) {
                return true
              }
            })
            this.detailListContact = filter
            this.totalData = filter.length
          } else {
            this.detailListContact = paginate(data, itemsPerPage, page)
            this.totalData = data.length
          }
          this.headerContact = [
            { text: 'Name', value: 'Name' },
            { text: this.$t('phoneNumber'), value: 'phone_number' },
            { text: 'Message', value: 'Message' },
            { text: 'Media', value: 'Media' },
            { text: 'Attachments', value: 'Attachments' },
            { text: 'Date', value: 'Date' },
            { text: 'Time', value: 'Time' },
          ]
          this.dataContacts = data
        }
        this.tableLoading = false
        // this.listFields = _uniq(JSON.parse(JSON.stringify(tempField)))
        // this.headersImport = [
        //   { text: this.$t('name'), value: this.listFields[0] },
        //   { text: this.$t('phoneNumber'), value: this.listFields[1] },
        // ]
        // this.importPreviewData = data
      }
    },
    getCustomAttributes(item) {
      //console.log(item)
      let data = ''
      for (let key in item) {
        if (
          key !== 'channel_source' &&
          key !== 'updatedAt' &&
          key !== 'createdAt' &&
          key !== 'phone_number' &&
          key !== 'data' &&
          key !== 'contactName' &&
          key !== 'phone_number_show' &&
          key !== '_id' &&
          key !== 'name' &&
          key !== 'updated_at' &&
          key !== 'updated_at' &&
          key !== 'created_at' &&
          key !== '_uid' &&
          key !== 'contact_source' &&
          key !== 'no' &&
          key !== 'phoneNumber' &&
          key !== 'archived' &&
          key !== 'message_from_me' &&
          key !== 'last_reply' &&
          key !== 'sync_firestore' &&
          key !== 'chunkReady' &&
          key !== 'last_message_status' &&
          key !== 'path' &&
          key !== 'roomStatus' &&
          key !== 'lastUpdatedMessageId' &&
          key !== 'lastFirestoreMessageId' &&
          key !== '_rev' &&
          key !== 'message_container' &&
          key !== 'pinned' &&
          key !== 'subId' &&
          key !== 'last_interaction' &&
          key !== 'assign_to' &&
          key !== 'instance_id' &&
          key !== 'unreplied' &&
          key !== 'roomId' &&
          key !== 'users' &&
          key !== 'id' &&
          key !== 'roomName' &&
          key !== 'roomOwnerId' &&
          key !== 'lastUpdated' &&
          key !== 'lastMessage' &&
          key !== 'roomOwnerName' &&
          key !== 'profile_picture' &&
          key !== 'unread_count' &&
          key !== 'last_message' &&
          key !== 'lastChunkMessageId'
        )
          data += `${key}: ${item[key]} `
      }
      return data
    },
    checkTimePicker(varnew) {
      if (varnew !== '') {
        let dateChoose = new Date(varnew)
        let dateNow = new Date()
        if (
          dateNow.getDate() === dateChoose.getDate() &&
          dateNow.getMonth() === dateChoose.getMonth() &&
          dateNow.getFullYear() === dateChoose.getFullYear()
        ) {
          let timeChoose = this.timePicker.split(':')
          let hoursChoose = timeChoose[0]
          let minutesChoose = timeChoose[1]
          let totalChoose = Number(hoursChoose) * 60 + Number(minutesChoose)

          let totalNow = Number(new Date().getHours()) * 60 + Number(new Date().getMinutes())
          if (totalChoose < totalNow) {
            let timePickerHours = new Date().getHours() > 9 ? `${new Date().getHours()}` : `0${new Date().getHours()}`
            let timePickerMinutes =
              new Date().getMinutes() > 9 ? `${new Date().getMinutes()}` : `0${new Date().getMinutes()}`
            this.timePicker = `${timePickerHours}:${timePickerMinutes}`
          }
        }
      }
    },
    populateLocalField(dataField) {
      const extraField = dataField
      const globalField = extraField.global.map(el => el.field)

      const tempLocalField = []
      const keys = Object.keys(extraField)
      // eslint-disable-next-line no-plusplus
      for (let index = 2; index < keys.length; index++) {
        const el = keys[index]
        extraField[el].forEach(elm => {
          if (!globalField.includes(elm.field)) tempLocalField.push(`{{${elm.field}}}`)
        })
      }
      this.localExtraFieldContainer = tempLocalField
    },
    checkExtraFieldAv() {
      if (this.selectedListContacts.length > 1) {
        const regexp = /{{\s*[\w\.]+\s*}}/g
        const match = this.listTemplates[this.messageTemplate].message.match(regexp)
        let countLocalExtraField = 0
        if (match) {
          match.forEach(el => {
            if (this.localExtraFieldContainer.includes(el)) countLocalExtraField += 1
          })
        }
        this.extraFieldAlert = countLocalExtraField !== 0
      } else {
        this.extraFieldAlert = false
      }
    },
    async showContactInTable(searchValue) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const initialHeader = [
        { text: this.$t('phoneNumber'), value: 'phone_number' },
        { text: this.$t('name'), value: 'name' },
        { text: 'Contact', value: 'source' },
      ]
      try {
        this.tableLoading = true
        if (this.sourceContact === 'Whatsapp') {
          this.headerContact = initialHeader
          this.detailListContact = []
          this.selectedTags = []
          this.fromExcel = false
          // const source = this.sourceContact === 'Whatsapp' ? 'wa' : 'chatwoot'
          let foundContacts = await clientPocketBase.collection('crm_contact').getList(page, itemsPerPage, {
            filter: `instance = "${this.listInstance[this.channel].id}" && source = "wa" ${
              searchValue ? `(name ~ "${searchValue}" || phone_number ~ "${searchValue}")` : ''
            }`,
            $autoCancel: false,
          })
          this.totalData = foundContacts.totalItems
          this.detailListContact = foundContacts.items
          this.tableLoading = false
        } else if (this.sourceContact === 'Excel') {
          this.headerContact = initialHeader
          this.selectedTags = []
          this.fromExcel = true
          this.showListContact = false
          this.detailListContact = []
          this.dataContacts = []
          this.tableLoading = false
        } else if (this.sourceContact === 'Tags') {
          this.headerContact = [
            { text: this.$t('phoneNumber'), value: 'phone_number' },
            { text: this.$t('name'), value: 'name' },
            { text: `Last Message`, value: 'last_msg' },
          ]
          this.selectedTags = []
          this.fromExcel = false
          this.detailListContact = []
          // let fetchTags = await clientPocketBase.collection('crm_tags').getFullList(400, {
          //   $autoCancel: false,
          // })
          const { data: fetchTags } = await axios.get(
            `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/fetchTags/${this.user.sub_id}`,
          )
          this.listTags = fetchTags
          this.showContactInTableFromTags()
        }
      } catch (error) {
        console.log(error, 'err sayang')
      } finally {
        this.tableLoading = false
      }
    },
    async showContactInTableFromTags(searchValue) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.tableLoading = true
      if (this.selectedTags.length) {
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/fetch-label-contacts`
        const payload = {
          tags: this.selectedTags.map(tag => tag.id),
          variants: this.selectedTags.map(tag => tag.v),
          excludedTags: this.selectedExcludedTags.map(tag => tag.id),
          page: page - 1,
          itemsPerPage,
          sub_id: this.user.sub_id,
          search: searchValue,
          sortBy,
          sortDesc,
        }
        try {
          const { data: fetchLabels } = await axios.post(endpoint, payload)
          fetchLabels.data.contactLabels.forEach(contact => {
            contact.phone_number = contact.phone_number.replace(/'/g, '')
          })
          this.detailListContact = fetchLabels.data.contactLabels

          this.totalData = fetchLabels.data.totalPages
          this.tableLoading = false
        } catch (error) {
          console.log('🚀 ~ file: AddBroadcast.vue:1510 ~ showContactInTableFromTags ~ error:', error)
        }
      } else {
        this.tableLoading = false
        this.detailListContact = []
      }
    },
    checkDelayType() {
      if (this.delayType === 'auto') {
        this.delay = 10000
      }
      if (this.delayType === 'fast') {
        this.delay = 6000
      }
      if (this.delayType === 'custom') {
        this.customDelayDialog = true
      }
    },
    checkExecType() {
      if (this.executionType.value === 'immediately') {
        this.showMenuDateTime = false
        this.datePicker = ''
        this.timePicker = ''
      } else if (this.executionType.value === 'datetime') {
        this.showMenuDateTime = true
        const tempDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
        tempDate.setMinutes(tempDate.getMinutes() + 1)
        this.datePicker = tempDate.toISOString().substring(0, 10)
        this.timePicker = tempDate.toISOString().substring(11, 16)
      } else if (this.executionType.value === 'recurrence') {
        // do nothing
      } else {
        this.showMenuDateTime = true
        this.timePicker = this.executionType.value
        const timeNow = new Date().getHours() * 60 + new Date().getMinutes()
        const fromTimePicker = Number(this.timePicker.substring(0, 2)) * 60 + Number(this.timePicker.substring(3))
        let tempDate = new Date()
        if (fromTimePicker < timeNow)
          tempDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000 + 24 * 60 * 60 * 1000)
        else tempDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
        this.datePicker = tempDate.toISOString().substring(0, 10)
      }
    },
    stepThree() {
      const required = []
      if (this.messageTemplate === '' && !this.changeMessage) {
        required.push(this.$t('broadcasts.messageTemplate'))
      }
      if (this.broadcastName === '') {
        required.push(this.$t('broadcasts.broadcastName'))
      }

      if (this.fromExcel && !this.importFileExcel) {
        required.push('No File Selected!')
      }
      if (this.dataContacts.length === 0 && this.fromExcel) {
        required.push('No Broadcast selected')
      }

      if (required.length === 0) {
        this.activeStep = 3
      } else {
        if (required.length > 1) required.splice(required.length - 1, 0, '{--}')
        this.alertText = !this.fromExcel
          ? `${required.join(', ').replace(', {--},', ' and')} ${this.$t('broadcasts.mustFilled')}`
          : `${required.join(', ').replace(', {--},', ' and')}`
        this.alertDialog = true
        if (this.fromExcel) {
          this.dataContacts = []
        }
      }
    },
    stepTwo() {
      if (this.channel === '' || this.channel === null) {
        this.alertText = this.$t('dialog.selectChannel')
        this.alertDialog = true
      } else {
        if (this.dataContacts.length === 0) {
          this.alertText = this.$t('broadcasts.alertSelectContact')
          this.alertDialog = true
        } else {
          this.activeStep = 2
        }
      }
    },
    async preview() {
      const template = this.changeMessage === true ? this.listTemplateCustom : this.chosenTemplate
      const data = {
        sender: this.listInstance[this.channel],
      }
      const replaceMsg = `${template.message}\n\n\n(this is just preview message)`
      data.message_template = { ...template }
      data.message_template.message = replaceMsg
      const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
        /['"]+/g,
        '',
      )}superwa/couchdb/broadcasts/send-preview-message`
      const obj = {
        ...data,
      }

      this.loadingDialog = true
      this.loadingText = 'Sending Preview'

      await this.$axiosLimit
        .post(endpoint, obj, { headers: { Authorization: `Bearer ${this.user.token}` } })
        .then(() => {
          setTimeout(() => {
            this.loadingDialog = false
          }, 500)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.replace({ name: 'login' })
          }
          console.log(error)
        })
    },
    complete(draft = false) {
      this.$store.dispatch('link/sendLinkToBroadcast', '')
      let broadcastTime = null
      let execType = 'immediately'
      if (this.executionType.value === 'immediately') {
        broadcastTime = new Date().getTime()
      } else if (this.executionType.value === 'datetime') {
        if (this.datePicker && this.timePicker) {
          execType = 'datetime'
          broadcastTime = new Date(`${this.datePicker} ${this.timePicker}`).getTime()
        }
      } else if (this.executionType.value === 'recurrence') {
        execType = 'recurrence'
        const { repeatAt } = this.executionRecurrence
        const dateNow = new Date()
        const hoursAt = Number(repeatAt.substring(0, 2))
        const minutesAt = Number(repeatAt.substring(3))
        const timezone = dateNow.getTimezoneOffset()

        const utcTime = (hoursAt * 60 + minutesAt + timezone + 1440) % 1440
        const hoursAtUTC = Math.floor(utcTime / 60)
        const minutesAtUTC = utcTime % 60
        this.executionRecurrence.repeatAt = `${hoursAtUTC}:${minutesAtUTC}`
      } else if (this.datePicker && this.timePicker) {
        execType = 'datetime'
        broadcastTime = new Date(`${this.datePicker} ${this.timePicker}`).getTime()
      }
      //console.log(
      //   this.datePicker,
      //   this.timePicker,
      //   new Date(`${this.datePicker} ${this.timePicker}`).toString().match(/([A-Z]+[\+-][0-9]+.*)/),
      //   `INI TIME PICKER DAN DATE`,
      // )
      let data = null
      if (this.fromExcel) {
        const msgTemplate = []
        for (let contact of this.dataContacts) {
          broadcastTime = new Date(`${contact['Date']} ${contact['Time']}`).getTime()
          msgTemplate.push({
            template_name: 'Custom Template',
            category: {},
            language: '',
            message: contact['Message'],
            receiver: contact['Phone Number'].toString(),
            quick_reply: false,
            link_attachment: '',
            last_update: new Date().toISOString(),
            attachments:
              contact.Media || contact.Attachments
                ? [
                    {
                      uploadURL: contact.Media || contact.Attachments || '',
                      filename: contact.Media || contact.Attachments || '',
                      type: /[.]/.exec(contact.Media || contact.Attachments)
                        ? `image/${/[^.]+$/.exec(contact.Media || contact.Attachments)[0]}`
                        : undefined,
                    },
                  ]
                : [],
            id: this.$nanoid(),
            on_reply: false,
            on_reply_message: {
              message: '',
              attachments: [],
            },
            listButton: [],
            buttonHeader: '',
            buttonFooter: '',
            ad_hoc: true,
          })
          // //console.log(contact, broadcastTime, `${contact['Date']} ${contact['Time']}`)
        }

        data = {
          id: this.$Md5Hash(`${this.$nanoid()} - ${this.listInstance[this.channel].id}`),
          name: this.broadcastName,
          status: 'Processing',
          statistic: {
            read: 0,
            received: 0,
            replied: 0,
            sent: 0,
            skipped: 0,
            unsent: this.dataContacts.length,
          },
          is_paused: false,
          sub_id: this.user.sub_id,
          last_update: new Date().getTime(),
          broadcast_config: {
            mode: this.broadcastMode.value,
            speed: this.delay,
            deleteForMe: !this.showBroadcastMessage,
            type: 'normal',
            resend_time: 'default',
            timezone:
              this.executionType.value === 'immediately'
                ? new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]
                : new Date(`${this.datePicker} ${this.timePicker}`).toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1],
          },
        }

        this.loadingText = 'Creating Broadcast'
        this.chosenTemplate = msgTemplate

        if (draft) this.saveDraftBroadcasts(data, this.broadcastId)
        else if (this.isDraft) this.sendDraftBroadcasts(data, this.broadcastId)
        else {
          // this.insertBroadcasts(data)
          this.insertBroadcastsPB(data, true, broadcastTime)
        }
        // setTimeout(() => {
        //   this.loadingDialog = false
        //   this.$emit('close-dialog')
        //   // this.changeMessage = false
        // }, 1000)

        // //console.log(data, `INI DATANYA`)
      } else {
        // data yang mau dikirim ke pocketbase kayanya
        data = {
          id: this.$Md5Hash(`${this.$nanoid()} - ${this.listInstance[this.channel].id}`),
          name: this.broadcastName,
          status: this.executionType.value === 'immediately' ? 'Processing' : 'Scheduled',
          statistic: {
            read: 0,
            received: 0,
            replied: 0,
            sent: 0,
            skipped: 0,
            unsent: this.dataContacts.length,
          },
          is_paused: false,
          sub_id: this.user.sub_id,
          last_update: new Date().getTime(),
          broadcast_config: {
            mode: this.broadcastMode.value,
            speed: this.delay,
            deleteForMe: !this.showBroadcastMessage,
            type: 'normal',
            resend_time: 'default',
            timezone:
              this.executionType.value === 'immediately'
                ? new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]
                : new Date(`${this.datePicker} ${this.timePicker}`).toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1],
          },
          // contacts: this.dataContacts,
          // sub_id: this.user.sub_id,
          // sender: this.listInstance[this.channel],
          // message_template:
          //   this.changeMessage === true ? this.listTemplateCustom : this.listTemplates[this.messageTemplate],
          // schedule_send: broadcastTime,
        }
        data.contactList = this.selectedListContacts

        this.loadingText = 'Creating Broadcast'
        // this.loadingDialog = true
        //console.log(draft, this.isDraft, 'ini drafff')

        if (draft) this.saveDraftBroadcasts(data, this.broadcastId)
        else if (this.isDraft) this.sendDraftBroadcasts(data, this.broadcastId)
        else {
          // this.insertBroadcasts(data)

          this.insertBroadcastsPB(data, false, broadcastTime)
        }
      }
      this.$emit('close-dialog')
    },
    async insertBroadcastsPB(data, formExcel = false, broadcastTime) {
      try {
        let createdBc = await clientPocketBase.collection('crm_broadcasts').create(data)
        const contacts = this.dataContacts.map(contact => {
          return {
            id: this.$Md5Hash(`${this.$nanoid()} - ${this.listInstance[this.channel].id}`),
            broadcast_id: data.id,
            sub_id: this.user.sub_id,
            sender: {
              _id: this.listInstance[this.channel]._id,
              color: this.listInstance[this.channel].color,
              label: this.listInstance[this.channel].label,
              id: this.listInstance[this.channel].id,
              label_server: this.listInstance[this.channel].label_server,
              status: this.listInstance[this.channel].status,
              sub_id: this.listInstance[this.channel].sub_id,
              phone_number: this.listInstance[this.channel].phone_number,
            },
            sent_to: {
              id: contact.id,
              phone_number: contact.phone_number.toString().replace(/[']/g, ''),
              name: contact.name,
              profile_picture: contact.profile_picture,
              source: contact.source,
            },
            status: 'Processing',
            message: this.changeMessage === true ? this.listTemplateCustom : this.chosenTemplate,
            schedule_send: broadcastTime,
            total_recepients: this.dataContacts.length,
          }
        })

        await this.$axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/insert-bulk-broadcast-contacts`, {
          contacts,
        })

        if (this.executionType.value === 'immediately' && !this.fromExcel) {
          try {
            await axios.post(
              `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/send-broadcasts-immediately/${createdBc.id}`,
            )
          } catch {}
        } else {
          await axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/kala-broadcast-schedule`, {
            name: `Scheduled broadcast for ${createdBc.name}`,
            id: createdBc.id,
            email: this.listInstance[this.channel].label,
            schedule: `R0/${new Date(broadcastTime).toISOString()}/PT5S`,
            // url: `${process.env.REACT_APP_AYRSHARE_APP}/ayrshare/post-content/organic/${result.id}`,
            url: `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/send-broadcasts-immediately/${createdBc.id}`,
          })
        }

        this.$emit('close-dialog')

        this.loadingDialog = false
      } catch (error) {
        console.log(error, 'error insert broadcast to pocketbase')
      }
    },
    async saveDraftBroadcasts(data, idBroadcast) {
      data.sub_id = this.user.sub_id
      data.broadcastId = idBroadcast
      const endpoint = `${process.env.VUE_APP_BROADCAST_SERVICE_API_URL.replace(
        /['"]+/g,
        '',
      )}superwa/broadcasts/postgre/insert-draft`
      const obj = {
        subId: this.user.sub_id,
        data: data,
        broadcast_id: idBroadcast,
      }

      await this.$axiosLimit
        .post(endpoint, obj, { headers: { Authorization: `Bearer ${this.user.token}` } })
        .then(res => {
          //console.log(res, ' == res')
          setTimeout(() => {
            this.loadingDialog = false
            this.$store.dispatch('link/sendLinkToBroadcast', '')
            this.$emit('close-dialog')
          }, 1000)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.replace({ name: 'login' })
          }
          console.log(error)
        })
    },
    async sendDraftBroadcasts(data, idBroadcast) {
      data.sub_id = this.user.sub_id
      data.broadcastId = idBroadcast
      const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/broadcasts/postgre/send-draft`
      const obj = {
        subId: this.user.sub_id,
        data: data,
        broadcast_id: idBroadcast,
      }

      await this.$axiosLimit
        .post(endpoint, obj, { headers: { Authorization: `Bearer ${this.user.token}` }, timeout: 0 })
        .then(() => {
          setTimeout(() => {
            this.loadingDialog = false
            this.$store.dispatch('link/sendLinkToBroadcast', '')
            this.$emit('close-dialog')
          }, 1000)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.replace({ name: 'login' })
          }
          console.log(error)
        })
    },
    setMessagePreview() {
      const text = this.changeMessage === true ? this.listTemplateCustom : this.chosenTemplate
      this.messagePreview = text
    },
    isMini() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
        default:
          return false
      }
    },
    changeUnsubscribe() {
      const temp = { ...this.messagePreview }
      //console.log(temp.message, 'temp message', this.unsubscribe)
      if (this.unsubscribe) temp.message = temp.message + '\n\n\nClick This Link to Unsubscribe : http://www.link.com'
      else {
        let index = temp.message.search('\n\n\nClick This Link to Unsubscribe : http://www.link.com')
        temp.message = temp.message.substring(0, index)
      }
      this.messagePreview = { ...temp }
    },
  },
}
</script>
<style lang="scss" scoped>
.tag-combobox:disabled {
  color: primary;
}
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
  .v-data-table-header__icon {
    opacity: 1;
  }
}
@media only screen and (max-width: 578px) {
  .sm-text {
    font-size: 12px;
  }
  .display-none {
    display: none;
  }
  .display-mt-lg {
    margin-top: 10px !important;
  }
}
@media only screen and (min-width: 578px) {
  .display-lg {
    display: flex;
  }
}

.custom-header.v-stepper .v-divider::after {
  background: #f15858;
}
</style>
